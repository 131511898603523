@import 'ngx-toastr/toastr';

html {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul[class] {
  list-style: none;
}

img {
  display: block;
}

input,
button {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s;
  cursor: pointer;

  &:active, &:focus, &:hover {
    opacity: 0.85;
  }
}

html {
  height: 100%;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}


:root {
  --background: #F3F3F3;
  --tui-primary: #33125b;
  --tui-primary-hover: #7957b9;
  --tui-primary-active: #7957b9;

}

body {
  width: 100%;
  height: 100%;
  font-family: "Calibri", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: #080729;
  background: var(--background);
}

.router-block {
  width: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 60px;

  @media (max-width: 1024px) {
    margin-left: 0px;
    max-height: none;
    overflow: unset;

  }
}

.page-header-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.page-header {
  color: var(--tui-text-01);
  font-size: 2rem;
  line-height: 125%;
}

.back {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: default;

  tui-svg {
    cursor: pointer;
    margin-right: 15px;
  }

  span {
    font-size: 1.5rem;
  }
}

.info {
  &-block {
    &__elem {
      max-width: 350px;
    }
  }

  h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_svg {
    &_cursor {
      cursor: pointer;
    }
  }

  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 20px;

  ul {
    margin-top: 25px;

    li {
      font-weight: 600;
      font-size: 1.0rem;
      margin-bottom: 10px;

      span {
        font-weight: 400;
      }
    }
  }

  .bottom-button {
    margin-top: auto;
  }

}

.form {
  &-group {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal {
  &-content {
    margin-top: 20px;
  }
}

.table-header {
  font-size: 1.5rem;
  margin-bottom: 25px;
}
